import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from '../../components/Header';
import ClientsList from './list';
import ClientItem from './item';

const MenusPage = (props) => {
  return (
    <div>
      <Header />

      <Routes>
        <Route path='' element={<ClientsList />} />
        <Route path=':id' element={<ClientItem />} />
        <Route path='new' element={<ClientItem />} />
      </Routes>
    </div>
  );
};

export default MenusPage;
