import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Assets
import logo from '../assets/logo.svg';

// Component
import ProfileLink from './ProfileLink';

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';

// Add FontAwesome icons
library.add([
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserAlt,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
]);

// The Header creates links that can be used to navigate
// between routes.
class HeaderUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      about: false,
    };
  }

  render() {
    return (
      <header className='d-flex flex-wrap justify-content-between py-3 mb-4 border-bottom'>
        <Link
          className='d-flex align-items-center mb-3 mb-md-0 text-dark text-decoration-none'
          to='/dashboard'>
          <img
            src={logo}
            alt='logo'
            className='mr-3'
            style={{ width: '40px' }}
          />
          {/* <span className='fs-4'>LOGO</span> */}
        </Link>

        <ul className='nav nav-pills'>
          <li className='nav-item'>
            <ProfileLink />
          </li>
        </ul>
      </header>

      // <header className='app-header'>
      //   <nav id='main-menu' className='navbar border-bottom-0'>
      //     <Link className='navbar-brand title mr-2' to='/dashboard'>
      //       LOGO
      //     </Link>

      //     <div className='ml-auto mr-3'>
      //       <ProfileLink />
      //     </div>
      //   </nav>
      // </header>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(HeaderUser);
