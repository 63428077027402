import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { userActions } from '../actions';

// import logo from '../assets/icon-white.png' // relative path to image

// The Header creates links that can be used to navigate
// between routes.
class RecoverForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      sent: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let state = {};
    state[n] = v;
    this.setState(state);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    const { dispatch } = this.props;
    if (email) {
      dispatch(userActions.requestPassword(email));
      this.setState({ sent: true });
    }
  }

  render() {
    const { authentication } = this.props;
    const { email, sent } = this.state;

    if (sent) return <Navigate to='/login' />;

    return (
      <form className='row form-signin' onSubmit={this.handleSubmit}>
        <div className='col-12'>
          {sent && authentication.recover && (
            <small className='d-block text-success font-weight-bolder mb-3'>
              Hemos enviado un mensaje a tu casilla de correo.
            </small>
          )}
          {sent && !authentication.recover && (
            <small className='d-block text-danger font-weight-bolder mb-3'>
              {authentication.recovererror}
            </small>
          )}

          <input
            name='email'
            className='form-control form-control-lg'
            value={email}
            onChange={this.handleChange}
            placeholder='Email'
            autoFocus
            required
          />

          <Link to='/login' className='mt-4 d-block'>
            <small className='font-weight-bolder'>Ingresar con mi cuenta</small>
          </Link>

          {/* <div className='mt-5 text-center'>
            <small>
              Need help? Visit{' '}
              <a
                className='font-weight-bolder'
                href='https://app.help'
                target='_blank'
                rel='noopener noreferrer'>
                app.help
              </a>
            </small>
          </div> */}

          <div className='clearfix mt-4'>
            <button className='btn btn-primary btn-md mt-3 ml-auto mr-auto pr-4 pl-4 float-lg-right'>
              Enviar
            </button>
          </div>
        </div>

        <div className='col-sm-10 col-sm-offset-1 mt-3 d-block d-md-none'>
          <small className='d-block text-white mt-5'>Developed by DS</small>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  return {
    authentication,
  };
}

export default connect(mapStateToProps)(RecoverForm);
