import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { companiesActions } from '../../actions';

import List from '../../components/list';

const ListPage = (props) => {
  // Hooks
  const dispatch = useDispatch();
  const items = useSelector((state) => state.companies.items);

  useEffect(() => {
    dispatch(companiesActions.getAll());
  }, [dispatch]);

  // Logic
  const menusItems = items.map((i, ii) => {
    return {
      name: (
        <Link key={`link-edit-${ii}`} to={'/companies/' + i._id}>
          {i.name}
        </Link>
      ),
      slug: (
        <Link key={`link-edit-${ii}`} to={'/companies/' + i._id}>
          {i.slug}
        </Link>
      ),
      type: i.type,
      // redirect_url: i.redirect_url,
      email: i.email,
      created_at: (
        <small>{moment(i.created_at).format('MMM Do YYYY, h:mm a')}</small>
      ),
      actions: [
        <Link
          key={`btn-edit-${ii}`}
          to={'/companies/' + i._id}
          className='btn btn-sm btn-outline-primary'>
          Edit
        </Link>,
        <Link
          key={`btn-del-${ii}`}
          to={'/companies/delete/' + i._id}
          className='btn btn-sm btn-light text-danger ml-3'>
          <FontAwesomeIcon
            icon='trash-alt'
            size='1x'
            style={{ cursor: 'pointer' }}
          />
        </Link>,
      ],
    };
  });

  return (
    <div>
      <h2 className='font-weight-light mt-4'>
        Empresas
        <div className='float-right'>
          <Link to='/companies/new' className='btn btn-outline-primary'>
            Add new
          </Link>
        </div>
      </h2>
      {/* <hr/>  */}

      <div className='mt-3'>
        <List
          labels={['name', 'slug', 'type', 'email', 'Created_At', 'Actions']}
          fields={['name', 'slug', 'type', 'email', 'created_at', 'actions']}
          data={menusItems}
          size='table-sm'
        />
      </div>
    </div>
  );
};

export default ListPage;
