import React, { useEffect } from 'react';
// import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Actions
import { companiesActions } from '../../actions';

import ClientForm from './form';

const Item = (props) => {
  // Hooks
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const item = useSelector((state) =>
    state.companies.items.find((item) => item._id === id)
  );

  // Effect: Mount
  useEffect(() => {
    if (id) dispatch(companiesActions.fetchOne(id));
  }, [id, dispatch]);

  // Methods
  const submit = (item) => {
    if (item._id) {
      dispatch(companiesActions.updateItem(item));
    } else {
      dispatch(companiesActions.createItem(item));
    }
  };

  if (!id) return <div className='alert alert-info mt-5'>Loading...</div>;

  return <ClientForm item={item} onSubmit={submit} />;
};

export default Item;
