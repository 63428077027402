import { combineReducers } from 'redux';

import { authentication } from './authentication';
import { user } from './user';
import { users } from './users';
import { companies } from './companies';
import { menus } from './menus';
import { menuitems } from './menuitems';
import { orders } from './orders';

const rootReducer = combineReducers({
  authentication,
  user,
  users,
  companies,
  menus,
  menuitems,
  orders,
});

export default rootReducer;
