import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startOfWeek, addDays, format, isBefore, isToday } from 'date-fns';
import { es } from 'date-fns/locale';
import numeral from 'numeral';

// Components
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// Actions
import { ordersActions, userActions } from '../../actions';
import HeaderUser from '../../components/HeaderUser';

const OrderPage = (props) => {
  // State
  const [show, setShow] = useState(false);
  const [day, setDay] = useState(null);
  const [order, setOrder] = useState([]);

  // Hooks
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const myOrders = useSelector((state) => state.orders.items);

  // Effect: Mount
  useEffect(() => {
    dispatch(userActions.userStatus());
    dispatch(ordersActions.getAll());
  }, [dispatch]);

  // Logic
  const { store } = user;
  const { menus = [] } = store ? store : {};
  const orderTotal = order.reduce((acc, cur) => acc + cur.price, 0);

  // Methods
  const showMenu = (day) => {
    const date = week[day];
    const hasOrder = myOrders.find((o) => o.day === date.toISOString());
    setOrder(hasOrder ? [...hasOrder.items] : []);
    setDay(day);
    setShow(true);
  };

  const addItem = (item) => {
    setOrder([...order, item]);
  };

  const removeItem = (item) => {
    // Find the order
    const itemIndex = order.findIndex((i) => i._id === item._id);
    // Remove the item from the order
    const newOrder = [...order];
    // Only remove if the item is in the order
    if (itemIndex > -1) newOrder.splice(itemIndex, 1);
    // Set it
    setOrder(newOrder);
  };

  const submitOrder = () => {
    const date = week[day];
    const orderExists = myOrders.find((o) => o.day === date.toISOString());
    if (!orderExists)
      dispatch(
        ordersActions.createItem({
          items: order.map((item) => item._id),
          data: order,
          day: week[day],
        })
      );
    else
      dispatch(
        ordersActions.updateItem({
          _id: orderExists._id,
          items: order.map((item) => item._id),
          data: order,
          day: week[day],
        })
      );
    setShow(false);
  };

  // Force date format: '2022-02-28 12:00:00'
  const today = new Date();
  const future = addDays(today, 2);
  const deadline = new Date(
    future.getFullYear(),
    future.getMonth(),
    future.getDate(),
    12,
    0,
    0
  );
  console.log(today, deadline);

  const start = startOfWeek(today, { weekStartsOn: 1 });
  const week = [
    // Week 1
    start,
    addDays(start, 1),
    addDays(start, 2),
    addDays(start, 3),
    addDays(start, 4),
    // Week 2
    addDays(start, 7),
    addDays(start, 8),
    addDays(start, 9),
    addDays(start, 10),
    addDays(start, 11),
  ];

  // Week Blocks
  const weekDays = week.map((d, di) => {
    // Order for this day?
    const hasOrder = myOrders.find((o) => o.day === d.toISOString());
    const orderTotal = hasOrder
      ? hasOrder.items.reduce((acc, cur) => acc + cur.price, 0)
      : 0;

    const ordeDetails = hasOrder
      ? hasOrder.items.map((i, ii) => (
          <p
            key={`item-${ii}-${di}`}
            className='d-flex justify-content-between text-muted'>
            <small>{i.name}</small>{' '}
            <small>$ {numeral(i.price).format('0,0')}</small>
          </p>
        ))
      : null;

    // console.log('d', d);
    // console.log('today', today);

    return (
      <div key={`day-${di}`} className='col flex-stretch mb-5'>
        <div
          className={`card h-100 ${
            isBefore(d, deadline) ? 'bg-light' : isToday(d) ? 'bg-success' : ''
          } `}>
          <div className='card-body'>
            <h6 className='card-title'>
              <span className='text-capitalize'>
                {d ? format(d, 'eee dd MMM, yyyy', { locale: es }) : ''}
              </span>
            </h6>

            <hr />

            <div className='card-text'>
              {hasOrder ? (
                ordeDetails
              ) : (
                <p className='text-muted'>
                  {isBefore(d, deadline)
                    ? 'No puedes crear una orden para este día.'
                    : 'Puedes crear una orden para este día.'}
                </p>
              )}
            </div>
          </div>
          <div className='card-footer'>
            <div className='d-flex justify-content-between align-items-center'>
              {hasOrder ? (
                <span>${numeral(orderTotal).format('0,0')}</span>
              ) : (
                <span></span>
              )}
              {isBefore(d, deadline) ? (
                <button className='btn btn-secondary' disabled>
                  Elegir
                </button>
              ) : (
                <button
                  className='btn btn-primary'
                  disabled={hasOrder ? true : false}
                  onClick={() => showMenu(di)}>
                  {hasOrder ? 'Solicitada' : 'Ordenar'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });

  const menu_items = menus.map((menu, mi) => {
    const items = menu.items.map((item, index) => {
      const count = order.filter((i) => i._id === item._id).length;
      return (
        <div key={`mitem-${index}`} className='mb-4'>
          <div className='d-flex'>
            <div className='w-100'>
              <h6>
                {item.name}{' '}
                <span className='ms-3 text-muted'>
                  $ {numeral(item.price).format('0,0')}
                </span>
              </h6>
              <div>{item.description}</div>
            </div>

            <div>
              <div
                className='input-group input-group-sm float-end'
                style={{ width: '90px' }}>
                <button
                  onClick={() => removeItem(item)}
                  className='btn btn-dark'>
                  -
                </button>
                <input
                  type='number'
                  value={count}
                  min={0}
                  className='form-control text-center'
                />
                <button onClick={() => addItem(item)} className='btn btn-dark'>
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <h6 className='text-primary'>{menu.name}</h6>
        <hr />
        {items}
      </div>
    );
  });

  return (
    <div>
      {user.role === 'user' && <HeaderUser />}
      <div className='row mb-5'>
        <div className='d-lg-flex col-12 col-lg-10 offset-lg-1'>
          <div className='text-center'>
            {store?.logo && (
              <img
                src={store.logo}
                alt={store.name}
                className='img-fluid border mb-4'
                style={{ maxHeight: '165px' }}
              />
            )}
          </div>
          <div className='text-center text-lg-start ms-lg-4'>
            <h3 className='fw-normal'>{store?.name}</h3>
            <div className=''>
              <p className='lead mb-4'>
                Bienvenido <b className='text-primary'>{user.email}</b>, <br />
                ya puedes organizar tus órdenes para los siguientes días:
              </p>
              {store?.banner && (
                <a
                  href={store.banner}
                  target='_blank'
                  className='btn btn-primary'
                  rel='noopener noreferrer'>
                  Ver menú semanal
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-lg-10 offset-lg-1'>
          <div className='row row-cols-1 row-cols-lg-5'>{weekDays}</div>
        </div>
      </div>

      <Modal
        isOpen={show}
        centered={true}
        size='lg'
        toggle={() => setShow(false)}>
        <ModalHeader
          close={
            <button
              className='btn-close'
              onClick={() => setShow(false)}></button>
          }>
          Orden para el día{' '}
          <span className='text-capitalize'>
            {week[day]
              ? format(week[day], 'eee dd MMM, yyyy', { locale: es })
              : ''}
          </span>
        </ModalHeader>
        <ModalBody>{menu_items}</ModalBody>
        <ModalFooter>
          <h4 className='me-auto'>
            Total: ${numeral(orderTotal).format('0,0')}
          </h4>
          <button onClick={submitOrder} className='btn btn-lg btn-primary'>
            Listo
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderPage;
