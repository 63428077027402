import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

// Assets
import logo from './assets/logo.svg';

// Import CSS
import './App.css';

// Actions
import { userActions } from './actions';

// import queryString from 'query-string';
// import { history } from './helpers';
// import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
// import { HomePage } from './containers/home';

// Public
import HomePage from './containers/Home';
import JoinPage from './containers/Join';

import ErrorPage from './containers/Error';
import RecoveryPage from './containers/Recovery';
import RegisterPage from './containers/Register';
import LogoutPage from './containers/Logout';
// Signed-in
import OrdersPage from './containers/orders/index';
import OrdersSummaryPage from './containers/orders/Summary';
import ProfilePage from './containers/Profile';
// Admin Routes
import UsersPage from './containers/users/index';
import CompaniesPage from './containers/companies/index';
import MenusPage from './containers/menus/index';

// BaseApp Components
// import Header from './components/Header';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      finished: false,
      timeout: null,
    };

    // Bindings
  }

  componentDidMount() {
    const { dispatch, loggingOut } = this.props;

    // Loading...
    const timeout = setTimeout(() => {
      this.setState({ loading: false }, () => {
        setTimeout(() => this.setState({ finished: true }), 500);
      });
    }, 1000);
    this.setState({ timeout });

    if (!loggingOut) dispatch(userActions.userStatus());
  }

  render() {
    const { user } = this.props;
    const { loading, finished } = this.state;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div id='app' className='col-12'>
            <BrowserRouter basename='/web'>
              {/* <ScrollToTop /> */}
              <Routes>
                {/* Public Routes */}
                <Route exact path='/' element={<HomePage />} />

                <Route exact path='/join/:biz/:com' element={<JoinPage />} />

                <Route exact path='/login' element={<HomePage />} />

                <Route exact path='/logout' element={<LogoutPage />} />

                <Route exact path='/error' element={<ErrorPage />} />

                <Route exact path='/register' element={<RegisterPage />} />

                <Route exact path='/recover' element={<RecoveryPage />} />

                {/* Signed-in Routes */}
                <Route
                  exact
                  path='/profile'
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path='/dashboard'
                  element={
                    <PrivateRoute>
                      {user?.role === 'user' ? (
                        <OrdersPage />
                      ) : (
                        <OrdersSummaryPage />
                        // <Navigate to='/menus' />
                      )}
                    </PrivateRoute>
                  }
                />

                {/* Admin Routes */}
                <Route
                  exact
                  path='/users'
                  element={
                    <PrivateRoute>
                      <UsersPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='menus/*'
                  element={
                    <PrivateRoute>
                      <MenusPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  exact
                  path='companies/*'
                  element={
                    <PrivateRoute>
                      <CompaniesPage />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </BrowserRouter>
          </div>

          <div className={`${loading ? 'loading' : 'loaded'}`}>
            {!finished && (
              <div
                className='d-flex align-items-center justify-content-center loader position-absolute text-center bg-white'
                style={{
                  height: '100vh',
                  width: '100%',
                  top: 0,
                  left: 0,
                  zIndex: 999,
                }}>
                <div className=''>
                  <img src={logo} alt='logo' width='100px' />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, loggingOut } = authentication;
  return {
    user,
    loggingOut,
  };
}

export default connect(mapStateToProps)(App);
