import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

// Actions
import { menusActions } from '../../actions';

const Form = (props) => {
  // Props
  const { item, onSubmit } = props;

  // State
  const [status, setStatus] = useState('init');
  const [changed, setChanged] = useState(false);
  const [data, setData] = useState({ ...item });

  // Hooks
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.menus.items);

  // Effects: Mount
  useEffect(() => {
    if (item) {
      dispatch(menusActions.getAll());
    }
  }, [item, dispatch]);

  useEffect(() => {
    if (item) setData({ ...item });
  }, [item]);

  // Methods
  const handleChange = (e) => {
    let newData = { ...data };
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    newData[e.target.name] = value;
    newData['changed'] = true;
    setData(newData);
  };

  const handleSelectChange = (e) => {
    // Copy the data
    let newData = { ...data };
    // Get the changes
    const options = e.target.options;
    const name = e.target.name;
    const values = [].filter
      .call(options, (o) => o.selected)
      .map((o) => o.value);
    // Set the data
    newData[name] = values;
    newData['changed'] = true;
    setData(newData);
  };

  const submit = () => {
    if (onSubmit) onSubmit(data);
  };

  return (
    <div>
      <div className='row mt-4'>
        <div className='col-10 offset-1'>
          <h4>
            Empresa: {item && item.name}{' '}
            <small
              style={{
                fontSize: '12px',
                padding: '5px 8px',
                verticalAlign: 'middle',
              }}
              className={
                status === 'saving'
                  ? 'text-capitalize badge badge-pill badge-warning'
                  : 'text-capitalize badge badge-pill badge-success'
              }>
              {status === 'saving' ? 'saving...' : status}
            </small>
          </h4>
          <hr />
          <div className='row row-cols-2'>
            <div className='form-group col mb-3'>
              <label className='font-weight-bold'>Name</label>
              <div className='input-group'>
                <input
                  name='name'
                  className='form-control'
                  placeholder='Name'
                  value={data?.name ? data.name : ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='form-group col mb-3'>
              <label className='font-weight-bold'>Identificador de URL</label>
              <div className='input-group'>
                <input
                  name='slug'
                  className='form-control'
                  placeholder='Identificador de URL'
                  value={data?.slug ? data.slug : ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='form-group col mb-3'>
              <label className='font-weight-bold'>Logo URL</label>
              <div className='input-group'>
                <input
                  name='logo'
                  className='form-control'
                  placeholder='Logo URL'
                  value={data?.logo ? data.logo : ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='form-group col mb-3'>
              <label className='font-weight-bold'>Banner URL</label>
              <div className='input-group'>
                <input
                  name='banner'
                  className='form-control'
                  placeholder='Banner URL'
                  value={data?.banner ? data.banner : ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='form-group col mb-3'>
              <label className='font-weight-bold'>Menús disponibles</label>
              <select
                className='form-control'
                multiple={true}
                onChange={handleSelectChange}
                name='menus'
                value={data?.menus ? data.menus : []}>
                {menus.map((menu, mi) => {
                  return (
                    <option key={`op-${mi}`} value={menu._id}>
                      {menu.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className='float-right mt-3 mb-5'>
            <button className='btn btn-lg btn-primary' onClick={submit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
