import React from 'react';
import { connect } from 'react-redux';
import * as owasp from 'owasp-password-strength-test';

// Actions
import { userActions } from '../actions';

import Header from '../components/Header';
import HeaderUser from '../components/HeaderUser';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user ? { ...this.props.user } : {},
      pass_errors: [],
      submitted: false,
    };
    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  // Methods
  handleChange(e) {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.value;
    this.setState({ user });
    if (e.target.name === 'password') {
      const passTest = owasp.test(e.target.value);
      if (passTest.errors.length)
        this.setState({ pass_errors: passTest.errors });
      else this.setState({ pass_errors: [] });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { user } = this.state;
    // Validations
    if (
      user.password &&
      user.password.length > 0 &&
      user.password !== user.repassword
    )
      return this.setState({
        error: 'Password and password confirmation do not match.',
      });
    dispatch(userActions.update(user));
    this.setState({ submitted: true });
  }

  logOut() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  render() {
    const { profile_error } = this.props;
    const { user, submitted, error, pass_errors } = this.state;

    let passValidation = '';
    if (user.password && user.password.length > 0)
      passValidation =
        user.password !== user.repassword ? 'is-invalid' : 'is-valid';
    if (profile_error || pass_errors.length) passValidation = 'is-invalid';

    return (
      <div>
        {user.role !== 'user' && <Header />}
        {user.role === 'user' && <HeaderUser />}

        <div className='row mt-5'>
          <div className='col-12 col-lg-4 offset-lg-4'>
            {error && (
              <div className='alert alert-danger text-center'>{error}</div>
            )}

            <h2 className='font-weight-light'>Mis Datos</h2>
            <hr />

            {profile_error && (
              <div className='alert alert-danger'>{profile_error}</div>
            )}

            {submitted && !profile_error && pass_errors.length === 0 && (
              <div className='alert alert-success text-center'>
                Información actualizada.
              </div>
            )}

            <form className='mt-4' onSubmit={this.handleSubmit}>
              <div className='form-group mb-2'>
                <input
                  name='name'
                  className='form-control form-control-lg'
                  placeholder='Nombre'
                  value={user.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className='form-group mb-2'>
                <input
                  name='lastname'
                  className='form-control form-control-lg'
                  placeholder='Apellido'
                  value={user.lastname}
                  onChange={this.handleChange}
                />
              </div>
              <div className='form-group mb-2'>
                <input
                  className='form-control form-control-lg'
                  placeholder='email'
                  value={user.email}
                  disabled
                />
              </div>
              <div className='form-group mb-2'>
                <input
                  type='password'
                  name='password'
                  className={`form-control form-control-lg ${passValidation}`}
                  onChange={this.handleChange}
                  placeholder='Ingresa tu contraseña'
                />
              </div>
              <div className='form-group mb-2'>
                <input
                  type='password'
                  name='repassword'
                  className={`form-control form-control-lg ${passValidation}`}
                  onChange={this.handleChange}
                  placeholder='Repite tu contraseña'
                />
              </div>
              {pass_errors.length > 0 && (
                <div className='mb-3'>
                  <p className='mb-0'>
                    <small className='text-danger'>{pass_errors[0]}</small>
                  </p>
                </div>
              )}
              <div className='form-group mt-4'>
                <button className='btn btn-primary btn-lg btn-block'>
                  Actualizar
                </button>
              </div>
            </form>
            {/* <div className='form-group mt-5 mb-5'>
              <button
                onClick={this.logOut}
                className='btn btn-link text-danger btn-block'>
                Sign out
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication } = state;
  const { user, profile_error } = authentication;
  return {
    alert,
    user,
    profile_error,
  };
}

export default connect(mapStateToProps)(ProfilePage);
