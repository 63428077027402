import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as owasp from 'owasp-password-strength-test';

// Actions
import { userActions } from '../actions';

// import logo from '../assets/icon-white.png' // relative path to image

// The Header creates links that can be used to navigate
// between routes.
class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      repassword: '',
      code: this.props.code || '',
      store: this.props.store || '',
      visible: false,
      pass_errors: [],
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePass = this.togglePass.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.setRegError(null));
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let state = {};
    state[n] = v;
    this.setState(state);

    if (n === 'password') {
      // const passTest = owasp.test(v);
      // if (passTest.errors.length)
      if (v.length < 8)
        this.setState({
          pass_errors: ['La contraseña debe contener al menos 8 caracteres.'],
        });
      else this.setState({ pass_errors: [] });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password, repassword, code, store } = this.state;
    const { dispatch } = this.props;
    if (email && password.length > 0 && password === repassword) {
      dispatch(
        userActions.register({ email, password, repassword, code, store })
      );
    } else {
      dispatch(userActions.setRegError('Passwords do not match.'));
      // console.log({ email, password, repassword })
    }
  }

  togglePass() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { authentication } = this.props;
    // const title = game.data ? game.data.name : '';

    const { email, password, repassword, visible, pass_errors } = this.state;

    return (
      <form className='row form-signin' onSubmit={this.handleSubmit}>
        <div className='col-12'>
          {authentication.regerror && (
            <small className='d-block text-danger font-weight-bolder mb-3'>
              {authentication.regerror}
            </small>
          )}

          <input
            name='email'
            className='form-control form-control-lg'
            value={email}
            onChange={this.handleChange}
            placeholder='Email'
            autoFocus
            required
          />

          <input
            name='password'
            className='form-control form-control-lg mt-3'
            type={visible ? 'text' : 'password'}
            value={password}
            onChange={this.handleChange}
            placeholder='Contraseña'
            required
          />
          <span className='pass-switch' onClick={this.togglePass}>
            {visible ? 'Ocultar' : 'Mostrar'}
          </span>

          <input
            name='repassword'
            className='form-control form-control-lg mt-3'
            type={visible ? 'text' : 'password'}
            value={repassword}
            onChange={this.handleChange}
            placeholder='Repite tu contrasña'
            required
          />
          <span className='pass-switch' onClick={this.togglePass}>
            {visible ? 'Ocultar' : 'Mostrar'}
          </span>

          {pass_errors.length > 0 && (
            <div className='mt-2'>
              <p className='mb-0'>
                <small className='text-danger'>{pass_errors[0]}</small>
              </p>
              {/* {pass_errors.map((e) => (
                <p className='mb-0'>
                  <small key={e} className='text-danger'>
                    {e}
                  </small>
                </p>
              ))} */}
            </div>
          )}

          <Link to='/login' className='mt-4 d-block'>
            <small className='font-weight-bolder'>¿Ya tienes una cuenta?</small>
          </Link>

          {/* <div className='mt-5 text-center'>
            <small>
              Need help? Visit{' '}
              <a
                className='font-weight-bolder'
                href='https://app.help'
                target='_blank'
                rel='noopener noreferrer'>
                app.help
              </a>
            </small>
          </div> */}

          <div className='clearfix mt-4'>
            <button className='btn btn-primary btn-lg mt-3 ml-auto mr-auto pr-4 pl-4 float-lg-right'>
              Crear
            </button>
            {/* <Link
              to='/recover'
              className='btn btn-link btn-sm d-block d-lg-inline font-weight-bolder mt-3 float-lg-left'>
              Access Via Email
            </Link> */}
          </div>
        </div>

        <div className='col-sm-10 col-sm-offset-1 d-block d-md-none'>
          <small className='d-block mt-5'>Developed by DS</small>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  return {
    authentication,
  };
}

export default connect(mapStateToProps)(RegisterForm);
