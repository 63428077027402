import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

// Components
import CRUD from '../../components/CRUD';

// Actions
import { menuitemsActions } from '../../actions';

const Form = (props) => {
  // Props
  const { item, onSubmit } = props;

  // State
  const [status, setStatus] = useState('init');
  // const [changed, setChanged] = useState(false);
  const [data, setData] = useState({ ...item });

  // Hooks
  const dispatch = useDispatch();
  const menuitems = useSelector((state) => state.menuitems.items);

  // Effects: Mount
  useEffect(() => {
    if (item?.items) {
      dispatch(
        menuitemsActions.getAll({
          _id: item.items,
        })
      );
    }
  }, [item?.items, dispatch]);

  useEffect(() => {
    if (item) setData({ ...item });
  }, [item]);

  // Methods
  const handleChange = (e) => {
    let newData = { ...data };
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    newData[e.target.name] = value;
    newData['changed'] = true;
    setData(newData);
  };

  const submit = (values) => {
    if (onSubmit) onSubmit(values);
  };

  // console.log(data);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <h3 style={{ lineHeight: 1.3 }}>
            Menu: {item && item.name}{' '}
            <small
              style={{
                fontSize: '12px',
                padding: '5px 8px',
                verticalAlign: 'middle',
              }}
              className={
                status === 'saving'
                  ? 'text-capitalize badge badge-pill badge-warning'
                  : 'text-capitalize badge badge-pill badge-success'
              }>
              {status === 'saving' ? 'saving...' : status}
            </small>
          </h3>
        </div>
      </div>

      <div className='row mt-4'>
        <div className='col-5'>
          <div className='row row-cols-1'>
            <div className='form-group col mb-3'>
              <label className='font-weight-bold'>Name</label>
              <div className='input-group'>
                <input
                  name='name'
                  className='form-control'
                  placeholder='Name'
                  value={data?.name ? data.name : ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='form-group col'>
              <label className='font-weight-bold'>Description</label>
              <div className='input-group'>
                <input
                  name='description'
                  className='form-control'
                  placeholder='Description'
                  value={data?.description ? data.description : ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className='float-right mt-5 mb-5'>
            <button
              className='btn btn-lg btn-primary'
              onClick={() => submit(data)}>
              Actualizar
            </button>
          </div>
        </div>
        <div className='col-7'>
          <div className='card'>
            <div className='card-body'>
              <CRUD
                title='Items del menú'
                data={menuitems}
                fields={['name', 'description', 'price']}
                labels={['Nombre', 'Descripción', 'Precio']}
                idField='_id'
                titleField='name'
                onCreate={(newItem) => {
                  dispatch(
                    menuitemsActions.createItem(newItem, (createdItem) => {
                      const menuItems = [...data.items, createdItem._id];
                      const newMenu = { ...item, items: menuItems };
                      submit(newMenu);
                      setTimeout(() => window.location.reload(), 500);
                    })
                  );
                }}
                onUpdate={(newItem) => {
                  dispatch(menuitemsActions.updateItem(newItem));
                  setTimeout(() => window.location.reload(), 500);
                }}
                onDelete={(item) => {
                  dispatch(
                    menuitemsActions.updateItem({
                      _id: item._id,
                      status: 'deleted',
                    })
                  );
                  // window.location.reload();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
