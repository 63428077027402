import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './helpers';
// import registerServiceWorker from './registerServiceWorker';

// Main CSS
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// The main App component
import App from './App';

// Initialize Sentry
// if (process.env.NODE_ENV === 'production') sentryInit();

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

//registerServiceWorker();
