import React from 'react';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      status: 'init',
      changed: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleChange(e) {
    let item = { ...this.state.item };
    item[e.target.name] = e.target.value;
    item['changed'] = true;
    this.setState({ item });
  }

  submit(e) {
    e.preventDefault();
    const { item } = this.state;
    if (this.props.onSubmit) this.props.onSubmit(item);
  }

  render() {
    const { item } = this.state;
    const { schools } = this.props;

    if (!item) return <h2>Loading...</h2>;

    // console.log({schools})

    const schoolOptions = schools.map((s, si) => {
      return (
        <option key={`so-${si}`} value={s._id}>
          {s.name}
        </option>
      );
    });

    return (
      <form onSubmit={this.submit}>
        <div className='row'>
          <h2 className='col-12 sec-title' style={{ lineHeight: 1.3 }}>
            User: {item.email || ''}{' '}
            {/* <small 
                        style={{ fontSize: '12px', padding: '5px 8px', verticalAlign: 'middle' }} 
                        className={ status === 'saving' ? 'text-capitalize badge badge-pill badge-warning ml-2' : 'text-capitalize badge badge-pill badge-success ml-2' }>
                        { status === 'saving' ? 'saving...' : status }
                    </small> */}
            {item.magiclogin && (
              <a
                className='btn btn-link'
                href={item.magiclogin}
                target='_blank'
                rel='noreferrer'
                style={{
                  fontSize: '1rem',
                  padding: '5px 8px',
                  verticalAlign: 'middle',
                }}>
                <small>Log-in as this user</small>
              </a>
            )}
            <div className='float-right' style={{ lineHeight: 1 }}>
              <button className='btn btn-md btn-outline-primary' type='submit'>
                Save
              </button>
            </div>
          </h2>
        </div>

        <div className='row mt-4'>
          <div className='col-3'>
            <div className='form-group'>
              <label>Name</label>
              <div className='input-group input-group-lg'>
                <input
                  name='name'
                  className='form-control'
                  placeholder='Name'
                  value={item.name || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Lastname</label>
              <div className='input-group input-group-lg'>
                <input
                  name='lastname'
                  className='form-control'
                  placeholder='Lastname'
                  value={item.lastname || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Role</label>
              <div className='input-group input-group-lg'>
                <select
                  name='role'
                  value={item.role}
                  className='form-control'
                  onChange={this.handleChange}
                  required>
                  <option value=''>Select role</option>
                  <option value='administrator'>Administrator</option>
                  <option value='manager'>Manager</option>
                  <option value='user'>Student</option>
                  <option value='manager'>Instrutor</option>
                </select>
              </div>
            </div>
            <div className='form-group'>
              <label>School</label>
              <div className='input-group input-group-lg'>
                <select
                  name='school'
                  value={item.school ? item.school._id : ''}
                  className='form-control'
                  onChange={this.handleChange}>
                  <option value=''>Select</option>
                  {schoolOptions}
                </select>
              </div>
            </div>
          </div>

          <div className='col-3'>
            <div className='form-group'>
              <label>Email</label>
              <div className='input-group input-group-lg'>
                <input
                  name='email'
                  className='form-control'
                  placeholder='Email'
                  value={item.email || ''}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Status</label>
              <div className='input-group input-group-lg'>
                <select
                  name='status'
                  value={item.status}
                  className='form-control'
                  onChange={this.handleChange}
                  required>
                  <option value=''>Select status</option>
                  <option value='created'>Created</option>
                  <option value='active'>Active</option>
                  <option value='recovery'>Recovery</option>
                  <option value='deleted'>Deleted</option>
                </select>
              </div>
            </div>
            <div className='form-group'>
              <label>Password</label>
              <div className='input-group input-group-lg'>
                <input
                  name='password'
                  className='form-control'
                  type='password'
                  placeholder='Password'
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;
