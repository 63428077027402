import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const { loggedIn } = useSelector((state) => state.authentication);
  const { pathname } = useLocation();
  return loggedIn ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
      state={{ from: pathname }}
    />
  );
};

export default PrivateRoute;
