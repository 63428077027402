import { userConstants } from '../constants';

const initialState = {
  recover: false,
  loggingIn: false,
  loggingOut: false,
  loggedIn: false,
  user: null,
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_FAILURE:
      return {
        loggedIn: false,
        regerror: action.error,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
      };
    case userConstants.LOGOUT_REQUEST:
      return {
        ...state,
        user: {},
        loggedIn: false,
        loggingOut: true,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        user: null,
        loggedIn: false,
        loggingOut: false,
      };
    case userConstants.REQUESTPASS_SUCCESS:
      return {
        ...state,
        recover: true,
      };
    case userConstants.REQUESTPASS_FAILURE:
      return {
        ...state,
        recover: false,
        recovererror: action.error,
      };
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.user },
        profile_error: false,
      };
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        profile_error: action.error,
      };
    default:
      return state;
  }
}
