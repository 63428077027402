import { userActions } from './';
import { authHeader, handleJSONResponse, store, history } from '../helpers';

// Inner Helpers
const checkJSONRequest = (response) => {
  const dispatch = store.dispatch;
  return handleJSONResponse(response, (data, code) => {
    if (code === 401) dispatch(userActions.logout());
    if (code !== 401) history.push('/error?code=403');
  });
};

export const FETCHONE_SUCCESS = 'ORDERS_FETCHONE_SUCCESS';
export const CREATEONE_SUCCESS = 'ORDERS_CREATEONE_SUCCESS';
export const UPDATEONE_SUCCESS = 'ORDERS_UPDATEONE_SUCCESS';
export const GETALL_REQUEST = 'ORDERS_GETALL_REQUEST';
export const GETALL_SUCCESS = 'ORDERS_GETALL_SUCCESS';
export const GETALL_FAILURE = 'ORDERS_GETALL_FAILURE';

export const ordersActions = {
  fetchOne,
  createItem,
  updateItem,
  getAll,
  // delete: _delete
};

function fetchOne(id) {
  return (dispatch) => {
    fetch(`/api/orders/${id}`, {
      method: 'GET',
      headers: authHeader(),
    })
      .then(checkJSONRequest)
      .then((data) => dispatch(success(data[0] || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: FETCHONE_SUCCESS, item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function createItem(item) {
  return (dispatch) => {
    fetch(`/api/orders`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(item),
    })
      .then(checkJSONRequest)
      .then((data) => dispatch(success(data || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: CREATEONE_SUCCESS, item: item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function updateItem(item) {
  return (dispatch) => {
    fetch(`/api/orders/${item._id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(item),
    })
      .then(checkJSONRequest)
      .then((data) => {
        dispatch(success(data || null));
      });
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: UPDATEONE_SUCCESS, item: item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function getAll(filters = {}) {
  return (dispatch) => {
    dispatch(request());

    const qstring = Object.keys(filters)
      .filter((key) => !isNaN(filters[key]) || filters[key].length > 0)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');

    fetch(
      process.env.REACT_APP_API_URL +
        `/orders/all${qstring ? '?' + qstring : ''}`,
      {
        method: 'GET',
        headers: authHeader(),
      }
    )
      .then(checkJSONRequest)
      .then(
        (items) => dispatch(success(items)),
        (error) => dispatch(failure(error))
      );
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success(items) {
    return { type: GETALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: GETALL_FAILURE, error };
  }
}
