export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
  LOGOUT: 'USERS_LOGOUT',

  REQUESTPASS_REQUEST: 'USERS_REQPASS_REQUEST',
  REQUESTPASS_SUCCESS: 'USERS_REQPASS_SUCCESS',
  REQUESTPASS_FAILURE: 'USERS_REQPASS_FAILURE',

  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
};
