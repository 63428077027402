import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Assets
import logo from '../assets/logo.svg';

import ProfileLink from './ProfileLink';

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';

// Add FontAwesome icons
library.add([
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faLongArrowAltRight,
]);

// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;

    let menuItems = [];
    if (user.role === 'administrator')
      menuItems = [
        { url: '/library', label: 'Library' },
        { url: '/invites', label: 'Invites' },
        { url: '/users', label: 'Users' },
        { url: '/schools', label: 'Schools' },
        { url: '/clients', label: 'Clients' },
        { url: '/purchases', label: 'Purchases' },
        // { url: '/settings', label: 'Settings' }
      ];
    if (user.role === 'manager')
      menuItems = [
        { url: '/dashboard', label: 'Inicio' },
        { url: '/menus', label: 'Menus' },
        { url: '/companies', label: 'Empresas' },
      ];
    if (user.role === 'user')
      menuItems = [{ url: '/invites', label: 'Invites' }];

    const menuLinks = menuItems.map((l, li) => {
      return (
        <Link
          to={l.url}
          key={`menu-item-${li}`}
          className={
            li === menuItems.length - 1
              ? 'btn border-left pl-4 pr-4 border-right'
              : 'btn border-left pl-4 pr-4'
          }>
          {l.label}
        </Link>
      );
    });

    return (
      <header className='d-flex flex-wrap justify-content-between py-3 mb-4 border-bottom'>
        <Link
          className='d-flex align-items-center mb-3 mb-md-0 text-dark text-decoration-none'
          to='/dashboard'>
          <img
            src={logo}
            alt='logo'
            className='mr-3'
            style={{ width: '40px' }}
          />
          <span className='fs-5 ms-2 text-dark'>chefdays</span>
        </Link>

        <ul className='nav nav-pills'>
          <li className='nav-item me-3'>
            <span className='btn text-muted'>Hola {user?.email}</span>
          </li>
          {menuLinks}
          <li className='nav-item'>
            <ProfileLink />
          </li>
        </ul>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Header);
