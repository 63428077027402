import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CSVLink, CSVDownload } from 'react-csv';

// Components
import Header from '../../components/Header';

// Actions
import { ordersActions, companiesActions } from '../../actions';

// Constants
const STATES = {
  init: 'Solicitada',
  cancelled: 'Cancelada',
};
const format_cfg = { locale: es };

const OrdersSummaryPage = (props) => {
  // Sate
  const [filters, setFilters] = useState({});

  // Hooks
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.items);
  const orders = useSelector((state) => state.orders.items);

  // Effects: Mount
  useEffect(() => {
    // dispatch(ordersActions.getAll());
    dispatch(companiesActions.getAll());
  }, []);

  useEffect(() => {
    if (filters?.company)
      dispatch(
        ordersActions.getAll({
          ...filters,
          populate: 'user,items',
        })
      );
  }, [filters]);

  // Methods
  const handleChange = (e) => {
    let newData = { ...filters };
    const { name, value } = e.target;
    newData[name] =
      name === 'day_start' || name === 'day_end'
        ? new Date(value).toISOString()
        : value;
    // newData[name] = value;
    setFilters(newData);
  };

  const handleCancel = (_id) => {
    if (_id) dispatch(ordersActions.updateItem({ _id, status: 'cancelled' }));
  };

  const handleRestore = (_id) => {
    if (_id) dispatch(ordersActions.updateItem({ _id, status: 'init' }));
  };

  // Logic
  const fullSelectedCompany = companies.find((c) => c._id === filters.company);
  const tableData = orders?.reduce((acc, order, oi) => {
    const items = order.items.map((it, iti) => ({
      first: iti === 0,
      order_id: order._id,
      order_nitems: order.items.length,
      order_email: order.user.email,
      order_day: format(new Date(order.day), 'eee dd MMM, yyyy', format_cfg),
      order_created_at: format(
        new Date(order.created_at),
        'eee dd MMM, yyyy',
        format_cfg
      ),
      order_status: order.status,
      name: it.name,
      price: it.price,
      total: order.items.reduce((acc, cur) => acc + cur.price, 0),
    }));
    return acc.concat(...items);
  }, []);

  console.log({ tableData });

  return (
    <div>
      <Header />

      <div className='row'>
        <div className='col-12'>
          <form className='row gy-2 gx-3 align-items-center'>
            <div className='col-auto'>
              <select
                name='company'
                value={filters?.company}
                onChange={handleChange}
                className='form-select'>
                <option value=''>Selecciona una empresa</option>
                {companies.map((company) => {
                  return (
                    <option key={company._id} value={company._id}>
                      {company.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='col-auto'>
              <input
                type='date'
                name='day_start'
                className='form-control'
                onChange={handleChange}
              />
            </div>
            <div className='col-auto'>
              <input
                type='date'
                name='day_end'
                className='form-control'
                onChange={handleChange}
              />
            </div>
            <div className='col-auto'>
              <button type='submit' className='btn btn-primary'>
                Buscar
              </button>
            </div>
            {tableData.length > 0 && (
              <div className='col-auto'>
                <CSVLink
                  className='btn btn-outline-primary'
                  data={tableData.map((data, di) => ({
                    n: di + 1,
                    order_id: data.order_id,
                    email: data.order_email,
                    day: data.order_day,
                    created_at: data.order_created_at,
                    status: data.order_status,
                    total: data.total,
                    n_items: data.order_nitems,
                    name: data.name,
                    price: data.price,
                  }))}
                  filename={`${fullSelectedCompany.slug}.csv`}>
                  Exportar CSV
                </CSVLink>
              </div>
            )}
          </form>

          <hr />
        </div>
        <div className='col-12'>
          <table className='table table-sm align-middle'>
            <thead>
              <tr>
                <th>#</th>
                <th>Usuario</th>
                <th>Agendado</th>
                <th>Solicitado</th>
                <th>Estado</th>
                <th>Total</th>
                <th>Item</th>
                <th>Precio</th>
                <th className='text-center'>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, iti) => (
                <tr
                  key={item.order_id + '-' + iti}
                  className={
                    item.order_status === 'canceled'
                      ? 'bg-secondary bg-opacity-10 text-muted'
                      : ''
                  }>
                  {item.first && <td rowSpan={item.order_nitems}>{iti + 1}</td>}
                  {item.first && (
                    <td rowSpan={item.order_nitems}>{item.order_email}</td>
                  )}
                  {item.first && (
                    <td rowSpan={item.order_nitems}>{item.order_day}</td>
                  )}
                  {item.first && (
                    <td rowSpan={item.order_nitems}>{item.order_created_at}</td>
                  )}
                  {item.first && (
                    <td rowSpan={item.order_nitems}>
                      {STATES[item.order_status]}
                    </td>
                  )}
                  {item.first && (
                    <td rowSpan={item.order_nitems}>${item.total}</td>
                  )}
                  <td>{item.name}</td>
                  <td>${item.price}</td>
                  {item.first && (
                    <td
                      rowSpan={item.order_nitems}
                      className='text-center align-middle'>
                      {item.order_status === 'init' && (
                        <button
                          onClick={(e) => handleCancel(item.order_id)}
                          className='btn btn-sm btn-outline-danger'>
                          Cancelar
                        </button>
                      )}
                      {item.order_status === 'cancelled' && (
                        <button
                          onClick={(e) => handleRestore(item.order_id)}
                          className='btn btn-sm btn-outline-primary'>
                          Restaurar
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrdersSummaryPage;
