import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { menusActions } from '../../actions';

import List from '../../components/list';

const ListPage = (props) => {
  // Hooks
  const dispatch = useDispatch();
  const items = useSelector((state) => state.menus.items);

  useEffect(() => {
    dispatch(menusActions.getAll());
  }, [dispatch]);

  // Logic
  const menusItems = items.map((i, ii) => {
    return {
      name: (
        <Link key={`link-edit-${ii}`} to={'/menus/' + i._id}>
          {i.name}
        </Link>
      ),
      description: (
        <Link key={`link-edit-${ii}`} to={'/menus/' + i._id}>
          {i.description}
        </Link>
      ),
      items: i.items.length,
      created_at: (
        <small>{moment(i.created_at).format('MMM Do YYYY, h:mm a')}</small>
      ),
      actions: [
        <Link
          key={`btn-edit-${ii}`}
          to={'/menus/' + i._id}
          className='btn btn-sm btn-outline-primary'>
          Edit
        </Link>,
        <Link
          key={`btn-del-${ii}`}
          to={'/menus/delete/' + i._id}
          className='btn btn-sm btn-outline-danger ms-3'>
          <FontAwesomeIcon
            icon='trash-alt'
            size='1x'
            style={{ cursor: 'pointer' }}
          />
        </Link>,
      ],
    };
  });

  return (
    <div>
      <h3 className='font-weight-light mt-4'>
        Menus
        <Link to='/menus/new' className='btn btn-sm btn-outline-primary ms-3'>
          Agregar
        </Link>
      </h3>
      {/* <hr/>  */}

      <div className='mt-3'>
        <List
          labels={['Name', 'Description', 'Items', 'Created_At', 'Actions']}
          fields={['name', 'description', 'items', 'created_at', 'actions']}
          data={menusItems}
          size='align-middle table-smx'
        />
      </div>
    </div>
  );
};

export default ListPage;
