import React from 'react';
// import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../actions';

class LogoutPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount(){
        const { dispatch } = this.props
        dispatch( userActions.logout() )
    }

    render() {
        return 'Loging out...'
    }
}

const mapStateToProps = state => {
    const { user } = state.authentication;
    return {
        user
    };
}

export default connect(mapStateToProps)(LogoutPage)