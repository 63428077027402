export const authHeader = () => {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('dsuser'));
  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
};

export const handleJSONResponse = (response, onFailure) => {
  const errorCodes = [401, 403, 404, 500];
  return response
    .json()
    .then((data) => {
      if (!response.ok) {
        if (errorCodes.includes(response.status)) {
          onFailure && onFailure({ data, code: response.status });
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((err) => Promise.reject(err));
};

export const getCookie = (name) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  console.log({ ca });
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const roleFromJWT = (jwt_cookie = '') => {
  try {
    const jwt = jwt_cookie.length ? getCookie(jwt_cookie) : null;
    console.log({ jwt });
    const jwtParts = jwt.split('.');
    const decoded = decodeURIComponent(window.atob(jwtParts[1]));
    const { role } = JSON.parse(decoded);
    return role;
  } catch (e) {
    console.log(e);
    return null;
  }
};
