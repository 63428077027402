import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Modal, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { userActions } from '../actions';

// import FormInsReq from '../components/FormInsReq'

// The Header creates links that can be used to navigate
// between routes.
class ProfileLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    // Bindings
    this.logOut = this.logOut.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  logOut() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  submitInsReq(e) {
    e.preventDefault();
  }

  render() {
    const { user } = this.props;

    return (
      <div>
        <div className='text-right'>
          <div className=''>
            <Link to='/profile' className='btn btn-outline-light'>
              <FontAwesomeIcon className='ml-2 text-dark' icon='user-alt' />
            </Link>

            {/* {user.role !== 'user' && ( */}
            <button className='btn btn-outline-dark ms-3' onClick={this.logOut}>
              Salir
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(ProfileLink);
