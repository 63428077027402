import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
// import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { usersActions } from '../../actions';

import List from '../../components/list';
import ListFilters from '../../components/ListFilters';
import Pagination from '../../components/Pagination';

class UsersList extends React.Component {
  constructor(props) {
    super(props);

    let qs = queryString.parse(window.location.search);

    this.state = {
      items: [],
      pp: 20,
      qs: qs,
    };

    // Bindings
    this.handleSearch = this.handleSearch.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  componentDidMount() {
    const { qs, pp } = this.state;
    const { dispatch } = this.props;
    dispatch(usersActions.getAll({ status: 'active', pp, total: true, ...qs }));
  }

  handleSearch(filters) {
    this.setState({ qs: { ...this.state.qs, ...filters } }, () => {
      const { qs, pp } = this.state;
      const { dispatch } = this.props;
      dispatch(
        usersActions.getAll({ status: 'active', pp, total: true, ...qs })
      );
    });
  }

  pageChange(pag) {
    const { qs, pp } = this.state;
    const { dispatch } = this.props;
    dispatch(
      usersActions.getAll({ status: 'active', pp, pag, total: true, ...qs })
    );
  }

  deleteItem(item) {
    const { dispatch } = this.props;
    const toDelete = { ...item };
    toDelete.status = 'deleted';
    if (item._id) {
      dispatch(usersActions.updateItem(toDelete));
    }
  }

  render() {
    const { pp, qs } = this.state;
    const { users } = this.props;

    const userItems = users.items
      .filter((i) => i.status !== 'deleted')
      .map((i, ii) => {
        return {
          name: i.name,
          lastname: i.lastname,
          email: <Link to={'/users/' + i._id}>{i.email}</Link>,
          role: i.role,
          status: i.status,
          school: i.school ? i.school.name : '',
          last_login: i.last_login ? (
            <small>{moment(i.last_login).format('lll')}</small>
          ) : (
            '-'
          ),
          created_at: <small>{moment(i.created_at).format('lll')}</small>,
          actions: [
            <Link
              key={`btn-edit-${ii}`}
              to={'/users/' + i._id}
              className='btn btn-sm btn-outline-primary'>
              Edit
            </Link>,
            <button
              key={`btn-del-${ii}`}
              onClick={(e) => this.deleteItem(i)}
              className='btn btn-sm btn-light text-danger ml-3'>
              <FontAwesomeIcon
                icon='trash-alt'
                size='1x'
                style={{ cursor: 'pointer' }}
              />
            </button>,
          ],
        };
      });

    let calculoPag = users.total <= pp ? 1 : parseInt(users.total / pp, 10) + 1;

    return (
      <div>
        <h2 className='font-weight-light mt-4'>
          Users ({users.total})
          <Link to='/users/new' className='btn btn-outline-primary ml-4'>
            Add new
          </Link>
          <div className='float-right'>
            <ListFilters
              onSubmit={this.handleSearch}
              fields={[
                { name: 'name', placeholder: 'Name' },
                { name: 'lastname', placeholder: 'Lastname' },
                { name: 'email', placeholder: 'Email' },
                {
                  name: 'role',
                  placeholder: 'instructor, student, administrator...',
                },
              ]}
            />
          </div>
        </h2>
        {/* <hr/>  */}

        <div className='mt-4'>
          <List
            labels={[
              'Name',
              'Lastname',
              'Email',
              'Role',
              'Status',
              'School',
              'Last Login',
              'Created At',
              'Actions',
            ]}
            fields={[
              'name',
              'lastname',
              'email',
              'role',
              'status',
              'school',
              'last_login',
              'created_at',
              'actions',
            ]}
            data={userItems}
            size='table-sm'
          />
          <Pagination
            totalPages={calculoPag}
            pageChange={this.pageChange}
            qs={qs}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

const connectedUsersList = connect(mapStateToProps)(UsersList);
export { connectedUsersList as UsersList };
