import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

// Assets
import logo from '../assets/logo.svg';

// Components
import AbsoluteRedirect from '../components/AbsoluteRedirect';
// import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';

// Actions
// import { companiesActions } from '../actions';

const JoinPage = (props) => {
  // State
  const [business, setBusiness] = useState(null);
  const [company, setCompany] = useState(null);
  const [blogo, setLogo] = useState(null);

  // Hooks
  const { loggedIn } = useSelector((state) => state.authentication);
  const { biz, com } = useParams();
  const [searchParams] = useSearchParams();
  const prev = searchParams.get('prev');

  // Effects: Mount
  useEffect(() => {
    // Get the busines + company
    fetch(`/api/public/partnership/${biz}/${com}`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.business && data?.company) {
          const business = data.business?.name || data.business?.name;
          const business_logo = data.business?.logo || null;
          const company = data.company?.name || data.company?.name;
          setBusiness(business);
          setCompany(company);
          setLogo(business_logo);
        }
      });
  }, [biz, com]);

  // Logic
  if (loggedIn && prev)
    return <AbsoluteRedirect to={decodeURIComponent(prev)} />;

  // if (loggedIn && location.state && location.state.from)
  //   return <Navigate to={location.state.from} />;

  if (loggedIn) return <Navigate to='/dashboard' />;

  const isMobile = window.innerWidth < 500 ? true : false;

  if (!company) return null;

  return (
    <div className='row align-items-center h-100 bg-light'>
      <img
        src={logo}
        alt='logo'
        className='position-absolute top-0 left-0 mt-3 ms-3'
        style={{ width: '60px' }}
      />
      <div className='col-12 col-md-6 ms-md-auto me-md-auto col-lg-5 ms-lg-auto me-lg-auto text-center'>
        <div className={!isMobile ? 'card shadow' : 'card border-0'}>
          <div className={!isMobile ? 'card-body p-5' : 'card-body p-3 pt-5'}>
            {blogo && (
              <img
                className='img-fluid'
                style={{ maxWidth: '200px' }}
                src={blogo}
                alt={company}
              />
            )}

            <p className='lead mt-2 mb-4'>
              Crea tu cuenta junto a <b>{company}</b> y comienza a ordenar en{' '}
              <b>{business}</b>.
            </p>
            {/* {company && company.includes('http') ? (
              <img
                className='img-fluid mb-4'
                style={{ maxWidth: '200px' }}
                src={company}
                alt={company}
              />
            ) : (
              <h5 className='mb-4'>{company}</h5>
            )} */}

            {/* <p className='lead mt-4 mb-2'>Y comienza a ordenar en:</p> */}
            {/* {business && business.includes('http') ? (
              <img
                className='img-fluid mb-4'
                style={{ maxWidth: '200px' }}
                src={business}
                alt={business}
              />
            ) : (
              <h5 className='mb-4'>{business}</h5>
            )} */}

            <RegisterForm code={com} store={biz} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinPage;
