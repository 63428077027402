import React, { useState, useEffect } from 'react';

// Modal
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CRUD = (props) => {
  // Props
  const {
    title = '',
    fields = [],
    labels = [],
    data = [],
    idField = '',
    titleField = '',
    fetchAll,
    onCreate = null,
    onUpdate = null,
    onDelete = null,
  } = props;

  // State
  const [selected, setSelected] = useState(null);
  //   const [dataItems, setDataItems] = useState([]);

  // Effect: Init
  useEffect(() => {
    // get the data
    if (fetchAll) fetchAll();
  }, []);

  // Methods
  const newModal = () => setSelected({});
  const deselect = () => setSelected(null);

  const handleClick = (item) => {
    setSelected(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelected({ ...selected, [name]: value });
  };

  const handleUpsert = (e) => {
    if (selected?.[idField])
      return onUpdate && selected ? onUpdate(selected) : null;
    else return onCreate && selected ? onCreate(selected) : null;
  };

  // Logic
  const items = data.map((item, ii) => (
    <tr
      key={`item-${ii}`}
      className={item?.status === 'deleted' ? 'bg-light text-muted' : ''}>
      {fields.map((f, fi) => (
        <td className='me-auto' key={`field-${fi}`}>
          {typeof item[f] === 'boolean'
            ? item[f]
              ? 'True'
              : 'False'
            : item[f]}
        </td>
      ))}
      <td key={`field-actions-${item._id}`} className='text-end'>
        {onUpdate && (
          <button
            onClick={(e) => handleClick(item)}
            className='btn btn-sm btn-outline-primary'>
            Editar
          </button>
        )}
        {onDelete && (
          <button
            disabled={item.status === 'deleted'}
            onClick={() => onDelete(item)}
            className='btn btn-sm btn-outline-danger ms-2'>
            Eliminar
          </button>
        )}
      </td>
    </tr>
  ));

  return (
    <div>
      <h5>
        {title ? <span className='me-3'>{title}</span> : null}
        {onCreate ? (
          <button className='btn btn-sm btn-primary' onClick={newModal}>
            Agregar
          </button>
        ) : null}
      </h5>

      <hr />

      <table className='table table-hover'>
        <tbody>{items}</tbody>
      </table>
      <Modal isOpen={selected ? true : false} centered={true} toggle={deselect}>
        <ModalHeader toggle={deselect}>{selected?.[titleField]}</ModalHeader>
        <ModalBody>
          {selected
            ? fields.map((f, fi) => (
                <div key={`f-${fi}`} className='mb-3'>
                  <label>{labels[fi] || f}</label>
                  <input
                    key={`field-${fi}`}
                    className='form-control'
                    name={f}
                    value={selected[f]}
                    onChange={handleChange}
                  />
                </div>
              ))
            : null}
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-outline-dark' onClick={deselect}>
            Cerrar
          </button>
          <button className='btn btn-primary' onClick={handleUpsert}>
            {selected?.[idField] ? 'Actualizar' : 'Crear'}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CRUD;
