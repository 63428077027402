import { userActions } from './';
import { authHeader, handleJSONResponse, store, history } from '../helpers';

// Inner Helpers
const checkJSONRequest = (response) => {
  const dispatch = store.dispatch;
  return handleJSONResponse(response, (data, code) => {
    if (code === 401) dispatch(userActions.logout());
    if (code !== 401) history.push('/error?code=403');
  });
};

export const FETCHONE_SUCCESS = 'MENUS_FETCHONE_SUCCESS';
export const GETALL_REQUEST = 'MENUS_GETALL_REQUEST';
export const GETALL_SUCCESS = 'MENUS_GETALL_SUCCESS';
export const GETALL_FAILURE = 'MENUS_GETALL_FAILURE';
export const CREATEONE_SUCCESS = 'MENUS_CREATEONE_SUCCESS';
export const UPDATEONE_SUCCESS = 'MENUS_UPDATEONE_SUCCESS';

export const menusActions = {
  fetchOne,
  createItem,
  updateItem,
  getAll,
  // delete: _delete
};

function fetchOne(id) {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_URL}/menus/${id}`, {
      method: 'GET',
      headers: authHeader(),
    })
      .then(checkJSONRequest)
      .then((data) => dispatch(success(data[0] || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: FETCHONE_SUCCESS, item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function createItem(item) {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_URL}/menus`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(item),
    })
      .then(checkJSONRequest)
      .then((data) => dispatch(success(data || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: CREATEONE_SUCCESS, item: item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function updateItem(item) {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_API_URL}/menus/${item._id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(item),
    })
      .then(checkJSONRequest)
      .then((data) => {
        dispatch(success(data || null));
      });
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: UPDATEONE_SUCCESS, item: item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    fetch(`${process.env.REACT_APP_API_URL}/menus/all`, {
      method: 'GET',
      headers: authHeader(),
    })
      .then(checkJSONRequest)
      .then(
        (items) => dispatch(success(items)),
        (error) => dispatch(failure(error))
      );
  };

  function request() {
    return { type: GETALL_REQUEST };
  }
  function success(items) {
    return { type: GETALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: GETALL_FAILURE, error };
  }
}
