import React from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

class List extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            labels: this.props.labels || [],
            fields: this.props.fields || [],
            data: this.props.data || [],
            sort: -1,
            sortedBy: null
        }

        // Bindings

    }

    componentWillReceiveProps(newProps){ 
        this.setState({
            data: newProps.data
        });
    }

    sortTable( col ){
        const sort = this.state.sort;
        const field = this.state.fields[col]
        const sortFunction = (a,b) => {
            const aVal = a[field].value ? a[field].value : a[field];
            const bVal = b[field].value ? b[field].value : b[field];
            if(aVal < bVal) return -1*sort;
            if(aVal > bVal) return 1*sort;
            return 0;
        }
        let toBeSortedItems = [...this.state.data]
        let numericItems = toBeSortedItems.filter( i => i[field].value ? !isNaN(i[field].value) : !isNaN(i[field]) )
        let alphaItems   = toBeSortedItems.filter( i => i[field].value ? isNaN(i[field].value) : isNaN(i[field]) )
        numericItems.sort(sortFunction);
        alphaItems.sort(sortFunction);
        const sortedItems = numericItems.concat(alphaItems)
        this.setState({ data: sortedItems, sort: (this.state.sort * -1), sortedBy: col })
    }

    onRowClick( data, index ){
        if(this.props.onRowClick)
            this.props.onRowClick(data, index)
    }

    render(){

        const keys = this.state.fields;
        const headings = this.state.labels.map( (k,ki) => {
            const active = this.state.sortedBy === ki ? 'active' : '';
            const arrow  = this.state.sort === -1 ? '↑' : '↓'
            return <th key={'th-' + ki} onClick={ () => this.sortTable(ki) } className={'text-center ' + active}>
                { k } { active && <small className="badge badge-pill badge-primary">{ arrow }</small> }
            </th> 
        })
        const rows = this.state.data.map( (d,di) => {
            let cols = keys.map( (k, ki) => {
                let value = '';
                let label = '';
                if( d[k] ){
                    value = d[k].value ? d[k].value : d[k];
                    label = d[k].label ? d[k].label : value;
                }
                return <td onClick={ () => this.onRowClick(d, di) } key={'td-' + ki} className="text-center">
                    { label }
                </td> 
            })
            return <tr key={'tr-' + di}>{ cols }</tr>
        });

        const size = this.props.size || '';

        return <table className={"table table-hover " + size}>
            <thead className="bg-light">
                <tr>
                    { headings }
                </tr>
            </thead>
            <tbody>
                { rows }
            </tbody>
        </table>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

export default connect(mapStateToProps)(List);
