import React from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';

// Assets
import logo from '../assets/logo.svg';

// Components
import AbsoluteRedirect from '../components/AbsoluteRedirect';
import LoginForm from '../components/LoginForm';

const HomePage = (props) => {
  // Hooks
  const { loggedIn } = useSelector((state) => state.authentication);
  const { search, state } = useLocation();
  const [searchParams] = useSearchParams();
  const prev = searchParams.get('prev');

  // Effects: Mount
  // useEffect(() => {}, []);

  // Logic
  if (loggedIn && prev)
    return <AbsoluteRedirect to={decodeURIComponent(prev)} />;

  if (loggedIn && state?.from) return <Navigate to={`/${state?.from}`} />;

  if (loggedIn) return <Navigate to={`/dashboard${search}`} />;

  const isMobile = window.innerWidth < 500 ? true : false;

  return (
    <div className='row align-items-center h-100 bg-light'>
      <div className='col-12 col-md-6 ms-md-auto me-md-auto col-lg-4 ms-lg-auto me-lg-auto text-center'>
        <div className={!isMobile ? 'v-center' : ''}>
          <div className={!isMobile ? 'card shadowx' : 'card border-0'}>
            <div className={!isMobile ? 'card-body p-5' : 'card-body p-3 pt-5'}>
              <img src={logo} alt='logo' style={{ width: '80px' }} />
              <h4 className='mt-3 mb-3'>Ingresa</h4>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
