import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

// Assets
import logo from '../assets/logo.svg';

// Components
import RecoverForm from '../components/RecoverForm';

class RecoveryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  render() {
    const { user } = this.props;

    if (user && user.token) return <Navigate to='/dashboard' />;

    const isMobile = window.innerWidth < 500 ? true : false;

    return (
      <div className='row align-items-center h-100 bg-light'>
        <div className='col-12 col-md-6 ms-md-auto me-md-auto col-lg-4 ms-lg-auto me-lg-auto text-center'>
          <div className={!isMobile ? 'v-center' : ''}>
            <div className={!isMobile ? 'card shadow' : 'card border-0'}>
              <div
                className={!isMobile ? 'card-body p-5' : 'card-body p-3 pt-5'}>
                <img src={logo} alt='logo' style={{ width: '80px' }} />
                <h4 className='mt-3 mb-3'>Recuperar contraseña</h4>
                <RecoverForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(RecoveryPage);
